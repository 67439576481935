import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
// import ReactPlayer from 'react-player';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../setup';
// import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { getDeviceInfo } from '../../../redux/api';
import DeviceInfoTab from './Device/DeviceInfoTab';
import SendDriverMessageTab from './Device/SendDriverMessageTab';

interface InputProps {
    device: any,
}

const MapDeviceInfo: React.FC<InputProps> = ({ device }) => {
    const customerId: any = useSelector<RootState>(({ customer }) => customer.customerId, shallowEqual);
    const role = useSelector<RootState>(({ auth }) => auth.role, shallowEqual)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [deviceInfo, setDeviceInfo] = useState<any>({});
    const [activeTab, setActiveTab] = useState<string>('device_info');

    // useEffect(() => {
    //     let reqData: any = {
    //         customerId: customerId[0],
    //         id: device.Id
    //     }
    //     getDeviceInfo(reqData)
    //         .then(({ data: { Response } }) => {
    //             setIsLoading(false);
    //             if (Response.Results) {
    //                 setDeviceInfo(Response.Results[0]);
    //             }
    //         })
    //         .catch((e) => {
    //             setIsLoading(false)
    //         })

    //     return () => {
    //         setDeviceInfo({});
    //     }
    // }, []);

    useEffect(() => {
        if (device && device.Id) {
            loadDevice(device);
        } else {
            setDeviceInfo({});
        }
    }, [device])

    const loadDevice = (device: any) => {
        setIsLoading(true);

        let reqData: any = {
            customerId: customerId[0],
            id: device.Id
        }
        getDeviceInfo(reqData)
            .then(({ data: { Response } }) => {
                setIsLoading(false);
                if (Response.Results) {
                    setDeviceInfo(Response.Results[0]);
                }
            })
            .catch((e) => {
                setIsLoading(false)
            })

        setActiveTab('device_info');
    }

    // const generateVideoUrl = () => {

    //     //Remove Special characters

    //     let status = device.CallWord ? device.CallWord : '';

    //     const wordToRemove = ["offduty", "oncall", "hired", "vacant"];

    //     let regex = new RegExp("\\b" + wordToRemove.join('|') + "\\b", "gi");
    //     status = status.replace(regex, '');

    //     status = status.replace(/[^a-zA-Z]/g, "");

    //     return `https://api-itoplight-cloud.s3.amazonaws.com/campaign_videos/${status}.mp4`;
    // }

    if (isLoading) {
        return <div className='' style={{ width: '325px', fontSize: '0.925rem' }}>
            <span className='loading-text-line mb-2' style={{ width: '325px', height: '22px' }} ></span>
            <div className='row'>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>

                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '100px', height: '15px' }}></span>
                </div>
                <div className='col-md-6 mb-3'>
                    <span className='loading-text-line' style={{ width: '150px', height: '15px' }}></span><br></br>
                    <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span>
                    {/* <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span> */}
                    {/* <span className='loading-text-line' style={{ width: '70px', height: '15px' }}></span> &nbsp;&nbsp;<span className='loading-text-line' style={{ width: '180px', height: '15px' }}></span> */}
                </div>

                {/* <div className='col-md-12 mb-3'>
                    <span className='loading-text-line' style={{ width: '325px', height: '175px' }}></span><br></br>
                </div> */}
            </div>
        </div>
    }

    return (
        <div className='' style={{ minWidth: '325px', minHeight: '200px', fontSize: '0.925rem' }}>
            <Tabs
                // defaultActiveKey="device_info"
                onSelect={(key) => setActiveTab(key || 'device_info')}
                activeKey={activeTab}
                className="mb-5 fs-4 device-tabs"
            >

                <Tab eventKey="device_info" title="Device Info">
                    <DeviceInfoTab role={role} device={deviceInfo} />
                </Tab>

                <Tab eventKey="send_driver_message" title="Send driver message">
                    <SendDriverMessageTab device={deviceInfo} />
                </Tab>

            </Tabs>

        </div>
    )
}


export default MapDeviceInfo
