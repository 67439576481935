import moment from 'moment';
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DeviceInfoTab: React.FC<any> = ({ role, device }) => {

    return (
        <>
            {role !== 'Advertiser' &&
                <div className='d-flex text-dark'>
                    <div className='' style={{ width: '40%' }}>
                        <div className='mb-3'>
                            <strong >Vehicle No: </strong><br />
                            {device.VehicleNo}
                        </div>
                        <div className='mb-3'>
                            <strong>Firmware: </strong><br />
                            {device.Firmware}
                        </div>
                        <div className='mb-3'>
                            <strong>Last Login: </strong><br />
                            {device.LastLogin ? <>
                                <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip id='tooltip-user-name'>{moment(device.LastLogin).format('DD MMM YYYY h:mm a')}</Tooltip>}
                                >
                                    <div className='symbol symbol-35px symbol-circle'>

                                        {moment(device.LastLogin).fromNow()}
                                    </div>
                                </OverlayTrigger>
                            </> : '--'}
                        </div>
                    </div>
                    <div className='' style={{ width: '60%' }}>
                        <div className='mb-3'>
                            <strong>Serial No: </strong><br />
                            {device.Serial}
                        </div>
                        <div className='mb-3'>
                            <strong>App Version: </strong><br />
                            {device.AppVersion ? device.AppVersion : '--'}
                        </div>
                        <div className='mb-3'>
                            {(device.MessagesLastUpdated || device.PlaylistsLastUpdated || device.SchedulesLastUpdated) &&
                                <>
                                    <strong>Last Updates: </strong><br />

                                    {device.MessagesLastUpdated &&
                                        <><>Messages: </>{moment(device.MessagesLastUpdated).format('DD MMM YYYY h:mm a')}<br /></>
                                    }
                                    {device.PlaylistsLastUpdated &&
                                        <><>Playlists: </>{moment(device.PlaylistsLastUpdated).format('DD MMM YYYY h:mm a')}<br /></>
                                    }

                                    {device.SchedulesLastUpdated &&
                                        <><>Schedules: </> {moment(device.SchedulesLastUpdated).format('DD MMM YYYY h:mm a')}<br /></>
                                    }
                                </>
                            }
                        </div>
                    </div>
                    {/* <div className='col-md-5 mb-3'>
                        <strong>Firmware: </strong><br />
                        {device.Firmware}
                    </div>
                    <div className='col-md-7 mb-3'>
                        <strong>App Version: </strong><br />
                        {device.AppVersion ? device.AppVersion : '--'}
                    </div>
                    <div className='col-md-5 mb-3'>
                        <strong>Last Login: </strong><br />
                        {device.LastLogin ? <>


                            <OverlayTrigger
                                placement='top'
                                overlay={<Tooltip id='tooltip-user-name'>{moment(device.LastLogin).format('DD MMM YYYY h:m a')}</Tooltip>}
                            >
                                <div className='symbol symbol-35px symbol-circle'>

                                    {moment(device.LastLogin).fromNow()}
                                </div>
                            </OverlayTrigger>
                        </> : '--'}
                    </div>
                    {(device.MessagesLastUpdated || device.PlaylistsLastUpdated || device.SchedulesLastUpdated) &&
                        <div className='col-md-7 mb-3'>
                            <strong>Last Updates: </strong><br />

                            {device.MessagesLastUpdated &&
                                <><>Messages: </>{moment(device.MessagesLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }
                            {device.PlaylistsLastUpdated &&
                                <><>Playlists: </>{moment(device.PlaylistsLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }

                            {device.SchedulesLastUpdated &&
                                <><>Schedules: </> {moment(device.SchedulesLastUpdated).format('DD MMM YYYY h:m a')}<br /></>
                            }
                        </div>
                    } */}
                </div>
            }

            {/* <div className='row'>
                <div className='col-md-12' >
                    <ReactPlayer controls={true} width={300} playsinline={true} volume={0} muted={true} height={'auto'} loop={true} playing url={generateVideoUrl()} />
                </div>
            </div> */}
        </>
    )
}

export default DeviceInfoTab;
