import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from "formik";
import clsx from 'clsx';
import { KafkaSendMessage } from '../../../../redux/api';

const SendDriverMessageTab: React.FC<any> = ({
    device
}) => {
    const [loading, setLoading] = useState<any>(null);

    const initialValues: any = {
        Type: 'infomessage',
        Heading: '',
        Message: '',
    }

    const formSchema = Yup.object().shape({
        Type: Yup.string().required(' '),
        Heading: Yup.string().required(' '),
        Message: Yup.string().required(' '),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: formSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
        },
    });

    const handleType = (value: string) => {
        formik.setFieldValue("Type", value);
    }

    const checkValidation = () => {
        if (loading || !formik.values.Heading || !formik.values.Message) {
            return true;
        } else {
            return false;
        }
    }

    const resetForm = () => {
        formik.resetForm();
    }

    const generateHtmlPayload = (headingField: any, message: any) => {
        if (formik.values.Type == 'infomessage') {
            return `&lt;!DOCTYPE html&gt;&lt;html&gt;&lt;head&gt;&lt;link href=&quot;https://fonts.googleapis.com/css?family=Inter&amp;display=swap&quot; rel=&quot;stylesheet&quot;/&gt;&lt;link href=&quot;https://www.ptg.to/css/new.css&quot; rel=&quot;stylesheet&quot;/&gt;&lt;title&gt;${headingField}&lt;/title&gt;&lt;/head&gt;&lt;body class=&quot;body&quot;&gt;&lt;div class=&quot;page&quot;&gt;&lt;p&gt;&lt;img src=&quot;https://www.pointguard.mobi/images/pglogo.png&quot; alt=&quot;Pointguard Logo&quot; width=&quot;163&quot; height=&quot;31&quot;&gt;&lt;/p&gt;&lt;h1&gt;${headingField}&lt;/h1&gt;&lt;p&gt;${message}&lt;/p&gt;&lt;input type=&quot;button&quot; id=&quot;close&quot; onclick=&quot;closeDialog()&quot; value=&quot;CLOSE AND EXIT&quot; class=&quot;button&quot;&gt;&lt;script type=&quot;text/javascript&quot;&gt;function closeDialog() {Android.closeDialog();}&lt;/script&gt;&lt;/div&gt;&lt;/body&gt;&lt;/html&gt;`
        } else if (formik.values.Type == 'messagewithreply') {
            return `&lt;!DOCTYPE html&gt;&lt;html&gt;&lt;head&gt;&lt;link href=&quot;https://fonts.googleapis.com/css?family=Inter&amp;display=swap&quot; rel=&quot;stylesheet&quot;/&gt;&lt;link href=&quot;https://www.ptg.to/css/new.css&quot; rel=&quot;stylesheet&quot;/&gt;&lt;title&gt;${headingField}&lt;/title&gt;&lt;/head&gt;&lt;body class=&quot;body&quot;&gt;&lt;div class=&quot;page&quot;&gt;&lt;p&gt;&lt;img src=&quot;https://www.pointguard.mobi/images/pglogo.png&quot; alt=&quot;Pointguard Logo&quot; width=&quot;163&quot; height=&quot;31&quot;&gt;&lt;/p&gt;&lt;h1&gt;${headingField}&lt;/h1&gt;&lt;p&gt;${message}&lt;/p&gt;&lt;p&gt;Reply&lt;/p&gt;&lt;form&gt;&lt;textarea id=&quot;response&quot; name=&quot;response&quot; rows=&quot;4&quot; cols=&quot;50&quot;&gt;&lt;/textarea&gt;&lt;p&gt;&lt;input type=&quot;submit&quot; id=&quot;submit&quot; onclick=&quot;sendInputToAndroid()&quot; value=&quot;SEND REPLY&quot; class=&quot;button&quot;&gt;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&lt;input type=&quot;button&quot; id=&quot;close&quot; onclick=&quot;closeDialog()&quot; value=&quot;CLOSE AND EXIT&quot; class=&quot;button&quot;&gt;&lt;/p&gt;&lt;/form&gt;&lt;/div&gt;&lt;script type=&quot;text/javascript&quot;&gt;function closeDialog() { Android.closeDialog(); } function sendInputToAndroid() { var input = document.getElementById(&#39;response&#39;).value; Android.receiveInput(input); }&lt;/script&gt;&lt;/body&gt;&lt;/html&gt;`
        } else if (formik.values.Type == 'messagewithconfirmationbutton') {
            return `&lt;!DOCTYPE html&gt;&lt;html&gt;&lt;head&gt;&lt;link href=&quot;https://fonts.googleapis.com/css?family=Inter&amp;display=swap&quot; rel=&quot;stylesheet&quot;/&gt;&lt;link href=&quot;https://www.ptg.to/css/new.css&quot; rel=&quot;stylesheet&quot;/&gt;&lt;title&gt;${headingField}&lt;/title&gt;&lt;/head&gt;&lt;body class=&quot;body&quot;&gt;&lt;div class=&quot;page&quot;&gt;&lt;p&gt;&lt;img src=&quot;https://www.pointguard.mobi/images/pglogo.png&quot; alt=&quot;Pointguard Logo&quot; width=&quot;163&quot; height=&quot;31&quot;&gt;&lt;/p&gt;&lt;h1&gt;${headingField}&lt;/h1&gt;&lt;p&gt;${message}&lt;/p&gt;&lt;p&gt;&lt;input type=&quot;button&quot; id=&quot;accept&quot; onclick=&quot;sendInputToAndroid()&quot; value=&quot;ACCEPT&quot; class=&quot;button&quot;&gt;&amp;nbsp;&amp;nbsp;&amp;nbsp;&amp;nbsp;&lt;input type=&quot;button&quot; id=&quot;deny&quot; onclick=&quot;sendInputToAndroid()&quot; value=&quot;DENY&quot; class=&quot;button&quot;&gt;&lt;/p&gt;&lt;/div&gt;&lt;/body&gt;&lt;/html&gt;`
        }
    };

    const buildRequest = () => {
        let data: any = {
            "ExpiryMode": "NEVER_EXPIRE",
            "Payload": generateHtmlPayload(formik.values.Heading, formik.values.Message),
            "PayloadPriority": 1,
            "PayloadType": "AppPopup",
            "Recipients": {
                "CustomerIds": [],
                "DeviceGroupIds": [],
                "DeviceIds": [device.Id]
            },
            "SendMode": "IMMEDIATE"
        };

        return data;
    }

    const submitMessageToDevice = () => {
        setLoading(true)

        const id = toast.loading(`Sending message...`);
        const requestBody = buildRequest();

        KafkaSendMessage(requestBody).then(({ data }: any) => {
            if (data.Status == 200) {
                toast.update(id, { render: `Message sent.`, type: "success", isLoading: false });
                resetForm();
            } else {
                toast.update(id, { render: `${data.Error} : ${data.Message}`, type: "error", isLoading: false });
            }
            setTimeout(() => {
                toast.dismiss(id);
            }, 5000);
            setLoading(false);
        }).catch((res: any) => {
            toast.update(id, { render: "Error while sending message", type: "error", isLoading: false });
            setTimeout(() => {
                toast.dismiss(id);
            }, 5000);
            setLoading(false);
        })
    }

    const isVersionGreaterOrEqual = (appVersion: any) => {
        const versionMatch = appVersion.match(/C-(\d+\.\d+\.?\d*)/);
        let targetVersion = '1.387.0';

        if (!versionMatch) return false;

        const extractedVersion = versionMatch[1];
        const extractedParts = extractedVersion.split('.').map(Number);
        const targetParts = targetVersion.split('.').map(Number);

        while (extractedParts.length < 3) {
            extractedParts.push(0);
        }
        while (targetParts.length < 3) {
            targetParts.push(0);
        }

        for (let i = 0; i < 3; i++) {
            if (extractedParts[i] > targetParts[i]) return true;
            if (extractedParts[i] < targetParts[i]) return false;
        }
        return true;
    };

    return (
        <>
            {(device && device.AppVersion && isVersionGreaterOrEqual(device.AppVersion)) ?
                <>
                    <div className='d-flex'>
                        <div className="col-12">
                            <div className=''>
                                <label className='d-flex fs-7 form-label fw-bolder text-dark align-items-end'>
                                    Message Type&nbsp;<span className='text-danger'>*</span>
                                </label>
                                <select
                                    {...formik.getFieldProps('Type')}
                                    onChange={(e) => handleType(e.target.value)}
                                    className='form-select form-select-lg form-select-solid fs-7'
                                    data-control='select2'
                                    data-placeholder='Select search by...'
                                >
                                    <option value={"infomessage"}>Info message</option>
                                    <option value={"messagewithreply"}>Message with reply</option>
                                    {/* <option value={"messagewithconfirmationbutton"}>Message with confirmation button</option> */}
                                </select>
                            </div>

                            <div className='mt-5'>
                                <label className='d-flex fs-7 form-label fw-bolder text-dark align-items-end'>
                                    Subject&nbsp;<span className='text-danger'>*</span>
                                </label>
                                <input
                                    placeholder='Subject'
                                    {...formik.getFieldProps('Heading')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid fs-7',
                                        { 'is-invalid': formik.touched.Heading && formik.errors.Heading },
                                    )}

                                    name='Heading'
                                    autoComplete='off'
                                />
                            </div>
                            <div className='mt-5'>
                                <label className='d-flex fs-7 form-label fw-bolder text-dark align-items-end'>
                                    Message&nbsp;<span className='text-danger'>*</span>
                                </label>
                                <textarea
                                    placeholder='Message'
                                    {...formik.getFieldProps('Message')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid fs-7',
                                        { 'is-invalid': formik.touched.Message && formik.errors.Message },
                                    )}
                                    name='Message'
                                    rows={3}
                                    autoComplete='off'
                                />
                            </div>
                            <div className='mt-5'>
                                <strong>Recipient:&nbsp;
                                    {device.Serial}
                                    {device.Serial && device.VehicleNo ? ' / ' : ''}
                                    {device.VehicleNo}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex gap-5 mt-5'>
                        <Button className='fw-bolder fs-7' size='sm' disabled={checkValidation()} variant='primary' onClick={submitMessageToDevice}>
                            {loading ? <>
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    <span className='spinner-border spinner-border-sm align-middle'></span>
                                </span>
                            </> :
                                <>Send message</>
                            }
                        </Button>
                        <Button className='fw-bolder fs-7' size='sm' variant='secondary' onClick={() => { resetForm() }}>
                            Clear
                        </Button>
                    </div>
                </> :
                <>
                    <div className='d-flex'>
                        <div className="col-12">
                            <label className='d-flex fs-7 form-label fw-bolder text-dark align-items-end'>
                                App version too old&nbsp;<span className='text-danger'>*</span>
                            </label>
                            <span className='text-gray-600'>
                                Please update drivers app to send messages
                            </span>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default SendDriverMessageTab