// import React, { useRef } from 'react';

// const PlaylistPreview = ({ previewUrl, height, width }: any) => {

//     const videoRef = useRef<HTMLVideoElement>(null);

//     const handleMouseEnter = () => {
//         if (videoRef.current) {
//             videoRef.current.play();
//         }
//     };

//     const handleMouseLeave = () => {
//         if (videoRef.current) {
//             videoRef.current.pause();
//             videoRef.current.currentTime = 0;
//         }
//     };

//     const cacheBustedUrl = `${previewUrl}?t=${new Date().getTime()}`;

//     return (
//         <React.Fragment>
//             <div className="preview-container" onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}>
//                 <video ref={videoRef} muted loop width={width || '180px'} height={height || '48px'}>
//                     <source src={cacheBustedUrl} type="video/mp4" />
//                     Your browser does not support the video tag.
//                 </video>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PlaylistPreview;

// import React, { useRef, useState } from 'react';

// const PlaylistPreview = ({ previewUrl, pngPreviewUrl, height, width, selectedIcon }: any) => {
//     const videoRef = useRef<HTMLVideoElement>(null);
//     const [isPlaying, setIsPlaying] = useState(false);

//     const handleMouseEnter = () => {
//         if (videoRef.current) {
//             videoRef.current.play();
//             setIsPlaying(true);
//         }
//     };

//     const handleMouseLeave = () => {
//         if (videoRef.current) {
//             videoRef.current.pause();
//             videoRef.current.currentTime = 0;
//             setIsPlaying(false);
//         }
//     };

//     const handleClick = () => {
//         if (videoRef.current) {
//             videoRef.current.play();
//             setIsPlaying(true);
//         }
//     };

//     const cacheBustedUrl = `${previewUrl}?t=${new Date().getTime()}`;
//     const cacheBustedPngUrl = `${pngPreviewUrl}?t=${new Date().getTime()}`;

//     return (
//         <React.Fragment>
//             <div
//                 className="preview-container"
//                 onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}
//                 onClick={handleClick}
//                 style={{ width: width || '180px', height: height || '48px', overflow: 'hidden' }}
//             >
//                 {!isPlaying && (
//                     <img
//                         src={cacheBustedPngUrl}
//                         alt="Playlist Preview"
//                         style={{
//                             width: '100%',
//                             objectFit: 'cover',
//                             height: height || '48px',
//                         }}
//                     />
//                 )}
//                 <video
//                     ref={videoRef}
//                     muted
//                     loop
//                     style={{
//                         display: isPlaying ? 'block' : 'none',
//                         width: width || '180px',
//                         height: height || '48px',
//                     }}
//                 >
//                     <source src={cacheBustedUrl} type="video/mp4" />
//                     Your browser does not support the video tag.
//                 </video>
//             </div>
//         </React.Fragment>
//     );
// };

// export default PlaylistPreview;

import React, { useRef, useState, useEffect } from 'react';

const PlaylistPreview = ({ previewUrl, pngPreviewUrl, height, width, selectedIcon }: any) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        if (selectedIcon === 'Autoplay' && videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        } else if (selectedIcon === 'Play') {
            if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.currentTime = 0;
            }
            setIsPlaying(false);
        }
    }, [selectedIcon]);

    const handleMouseEnter = () => {
        if (selectedIcon === 'Play' && videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
        }
    };

    const handleMouseLeave = () => {
        if (selectedIcon === 'Play' && videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const cacheBustedUrl = `${previewUrl}?t=${new Date().getTime()}`;
    const cacheBustedPngUrl = `${pngPreviewUrl}?t=${new Date().getTime()}`;

    return (
        <div
            className="preview-container"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ width: width || '180px', height: height || '48px', overflow: 'hidden' }}
        >
            {!isPlaying && (
                <img
                    src={cacheBustedPngUrl}
                    alt="Playlist Preview"
                    style={{
                        // width: '100%',
                        aspectRatio: '15 / 4',
                        objectFit: 'cover',
                        height: height || '48px',
                        objectPosition: 'left'
                    }}
                />
            )}
            <video
                ref={videoRef}
                muted
                loop
                autoPlay={selectedIcon === 'Autoplay'}
                style={{
                    display: isPlaying || selectedIcon === 'Autoplay' ? 'block' : 'none',
                    width: width || '180px',
                    height: height || '48px',
                }}
            >
                <source src={cacheBustedUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default PlaylistPreview;
