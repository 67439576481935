/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, } from 'react-leaflet'
import GeoMarkers from './GeoMarkers'
import moment from 'moment-timezone';
import _ from 'lodash';


interface InputProps {
    geoData: any,
    liveTracking: any,
    setLiveTracking: any,
    customerId: any,
}


const MapView: React.FC<InputProps> = ({ geoData, liveTracking, setLiveTracking, customerId }) => {

    const [deviceStatus, setDeviceStatus] = useState<any>("all");
    const [filteredGeoData, setFilteredGeoData] = useState<any>(geoData);


    const updateDeviceList = (e: any) => {
        setDeviceStatus(e.target.value);
    }

    useEffect(() => {
        const filteredData = geoData.filter(canPushtoGeoData);
        setFilteredGeoData(filteredData);
    }, [deviceStatus, geoData]);

    // useEffect(() => {
    //     const filteredData: any = []

    //     geoData.map((object: any, i: any) => {
    //         if (canPushtoGeoData(object)) {
    //             return filteredData.push(object)
    //         }
    //     });

    //     setFilteredGeoData(JSON.parse(JSON.stringify(filteredData)));
    // }, [deviceStatus, geoData])


    // const canPushtoGeoData = (object: any) => {
    //     if (object.Lat && object.Long) {
    //         if (deviceStatus === "24 hours") {
    //             // moment().subtract(24,'h')
    //             const result = moment().subtract(24, 'h').diff(moment(object.Time), "hours");

    //             if (result <= 24) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else if (deviceStatus === "7 days") {
    //             const result = moment(object.Time).diff(moment().subtract(6, 'd').startOf("day"), "days");
    //             if (result >= 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else if (deviceStatus === "30 days") {
    //             const result = moment(object.Time).diff(moment().subtract(29, 'd').startOf("day"), "days");
    //             if (result >= 0) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else if (deviceStatus === "all") {
    //             return true;
    //         }
    //     }
    //     return false;
    // }

    const canPushtoGeoData = (object: any) => {
        if (object.Lat && object.Long) {
            const objectTime = moment(object.Time);

            if (deviceStatus === "now") {
                return object.Online === true;
            } else if (deviceStatus === "24hours") {
                return moment().diff(objectTime, "hours") <= 24;
            } else if (deviceStatus === "7days") {
                return moment().startOf("day").diff(objectTime, "days") <= 7;
            } else if (deviceStatus === "30days") {
                return moment().startOf("day").diff(objectTime, "days") <= 30;
            } else if (deviceStatus === "all") {
                return true;
            }
        }
        return false;
    };


    let outerBounds: any = [];

    filteredGeoData.map((object: any, i: any) => {
        outerBounds.push([object.Lat, object.Long]);
    });

    if (outerBounds.length === 0) {
        outerBounds = [[59.84823989868164, 17.606433868408203]]
    }

    const handleLiveTrackingChange = (event: any) => {
        setLiveTracking(event.target.checked);
    };

    return (
        <>
            <MapContainer
                boundsOptions={{ padding: [1000, 1000] }}
                // scrollWheelZoom={false}
                bounds={outerBounds}
                style={{ width: '100%', height: '100%', zIndex: 1 }}
            >
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />

                <GeoMarkers liveTracking={liveTracking} geoData={filteredGeoData} outerBounds={outerBounds} />
                <div className='device-selection p-3 m-10' id='device-selection'>
                    <label className='fs-6 fw-bold'>Vehicles to show</label>
                    <select className='mt-2 form-select form-select-sm form-select-solid bg-white' onChange={updateDeviceList}>
                        <option value={"all"}>All</option>
                        <option value={"now"}>Online Now</option>
                        <option value={"24hours"}>Online last 24 hours</option>
                        <option value={"7days"}>Online last 7 days</option>
                        <option value={"30days"}>Online last 30 days</option>
                    </select>
                </div>
                {(customerId && customerId.length == 1) &&
                    < div className='live-tracking py-1 px-3 m-10' style={{ width: `${document?.getElementById("device-selection")?.offsetWidth}px` }}>
                        <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
                            <label className='fs-6 fw-bold mr-auto'>Live Tracking</label>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                // value={liveTracking}
                                name='notifications'
                                // defaultChecked={liveTracking == 'true'}
                                checked={liveTracking}
                                onChange={(e: any) => { handleLiveTrackingChange(e) }}
                            />
                        </div>
                    </div>}
            </MapContainer >

        </>
    )
}

export default MapView
