import React, { useEffect, useState } from 'react'
import _ from 'lodash';
import { toast } from 'react-toastify';
import { configurationCrudApi, deletePlayList } from '../../../../redux/api';

import DeletePlaylistModal from './DeletePlaylistModal'
import PlaylistRow from './PlaylistRow'
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import IconWithHoverAndSelect from '../../../playlist/components/PlayListTable/IconWithHoverAndSelect';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface props {
  configureList: any,
  getAllConfiguration: () => void,
  removePlaylist: (index: number) => void,
  playLists: any,
  isDashboard: boolean,
  type: any
}

const PlaylistTable: React.FC<props> = ({ configureList, getAllConfiguration, playLists, removePlaylist, isDashboard, type }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist]: any = useState(null);
  const [selectedIcon, setSelectedIcon] = useState<string | null>('Play');

  if (isDashboard == true) {
    configureList.Playlists = configureList.Playlists.slice(0, 10);
  }

  const getPlaylist = (id: number) => {
    return _.find(playLists, { Id: id });
  }

  const handleOpenModal = (message: any) => {
    setSelectedPlaylist(message);
    setIsOpenModal(true);
  }

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedPlaylist(null)
  }

  const handleDeletePlaylist = (index: number) => {
    setIsOpenModal(false);
    removePlaylist(index);

    // const id = toast.loading('Deleting message...')

    // deletePlayList(selectedPlaylist.Id)
    //   .then((res: any) => {
    //     if (res.data.Status == 200) {
    //       toast.update(id, {
    //         render: 'Playlist deleted successfully',
    //         type: 'success',
    //         isLoading: false,
    //       })
    //       getFilterPlaylists()
    //     } else {
    //       toast.update(id, { render: res.data.Message, type: 'error', isLoading: false })
    //     }

    //     setTimeout(() => {
    //       toast.dismiss(id)
    //     }, 5000)
    //   })
    //   .catch((e) => {
    //     toast.update(id, { render: 'Error while deleting playlist', type: 'error', isLoading: false })
    //   })

  }

  const previewPopOver = (
    <Popover id="popover-preview-details" className="max-width-300px text-center" style={{ fontSize: '0.925rem' }}>
      <Popover.Body>
        Choose how previews are shown.
        <br />
        <br />
        Start animation by hovering on image
        <br />
        Autoplay all animations
        <br />
        Show preview as images in order
      </Popover.Body>
    </Popover >
  );

  return (
    <>
      <div className={clsx('table-responsive ', { 'mt-5 pt-5': isDashboard != true })}>
        {type == 'normal' &&
          <table className={clsx('table align-middle table-row-bordered fs-6 no-footer ', isDashboard != true ? 'gy-5' : 'gy-1')} style={{ tableLayout: isDashboard == true ? 'fixed' : 'unset' }}>
            <thead>
              <tr className='fw-bolder'>
                <th style={{ width: isDashboard == true ? '35%' : "15%" }}>Title</th>
                {isDashboard != true &&
                  <th style={{ width: "25%" }}>Description</th>
                }
                <th style={{ width: isDashboard == true ? '55%' : "50%" }}>
                  <div className='d-flex align-items-center'>
                    <React.Fragment>
                      <OverlayTrigger
                        trigger={['hover', 'focus', 'click']}
                        overlay={previewPopOver}
                        rootClose
                      >
                        <div>
                          Preview
                        </div>
                      </OverlayTrigger>
                      <div className='d-inline-flex align-items-center ml-8'>
                        <IconWithHoverAndSelect selectedIcon={selectedIcon} setSelectedIcon={setSelectedIcon} />
                      </div>
                    </React.Fragment>
                  </div>
                </th>
                {isDashboard != true &&
                  <th style={{ width: "10%" }}>Action</th>
                }
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {configureList.Playlists && (
                <>
                  {configureList.Playlists?.map((configuration: any, i: any) => {
                    return <PlaylistRow selectedIcon={selectedIcon} type={type} isDashboard={isDashboard} key={`playlist${i}`} playlist={getPlaylist(configuration.PlaylistId)} configuration={configuration} index={i} removePlaylist={removePlaylist} />
                  })}
                </>
              )}
            </tbody>
          </table>
        }
        {type == 'integrators' &&
          <table className={clsx('table align-middle fs-6 no-footer gx-4 gy-5 unset border-spacing-8px')} >
            <thead>
              <tr className='fw-bolder'>
                <th >Call word</th>
                <th >Playlist to activate</th>
                <th >Dynamic Keys</th>
                <th >Direction and Messages</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {configureList.Playlists && (
                <>
                  {configureList.Playlists?.map((configuration: any, i: any) => {
                    return <PlaylistRow selectedIcon={selectedIcon} type={type} isDashboard={isDashboard} key={`playlist${i}`} playlist={getPlaylist(configuration.PlaylistId)} configuration={configuration} index={i} removePlaylist={removePlaylist} />
                  })}
                </>
              )}
            </tbody>
          </table>
        }
        {isDashboard == true && (configureList.Playlists.length > 0 && configureList.Playlists.length > 9) &&
          <div className='text-center'>
            <Link to='/configuration'>
              <i style={{ cursor: "pointer" }} className="fas fa-ellipsis-h fs-2"></i>
            </Link>
          </div>
        }
      </div >
      {/* <DeletePlaylistModal
        isOpenModal={isOpenModal}
        handleCloseModal={handleCloseModal}
        handleDeletePlaylist={handleDeletePlaylist}
        playlist={selectedPlaylist}
      /> */}
    </>
  )
}

export default PlaylistTable
