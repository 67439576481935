import { KTSVG } from '../../../../../_metronic/helpers';

const IconWithHoverAndSelect: React.FC<any> = ({ selectedIcon, setSelectedIcon }) => {
  // const [hoveredIcon, setHoveredIcon] = useState<string | null>(null);

  const icons = [
    { name: 'Play', path: '/media/svg/itoplight/Play.svg', hoverPath: '/media/svg/itoplight/Hover_Play.svg', selectedPath: '/media/svg/itoplight/Selected_Play.svg' },
    { name: 'Autoplay', path: '/media/svg/itoplight/Autoplay.svg', hoverPath: '/media/svg/itoplight/Hover_Autoplay.svg', selectedPath: '/media/svg/itoplight/Selected_Autoplay.svg' },
    { name: 'Image', path: '/media/svg/itoplight/Image.svg', hoverPath: '/media/svg/itoplight/Hover_Image.svg', selectedPath: '/media/svg/itoplight/Selected_Image.svg' }
  ];

  return (
    <div className='d-inline-flex align-items-center'>
      {icons.map((icon) => {
        const isSelected = selectedIcon === icon.name;
        // const isHovered = hoveredIcon === icon.name;

        return (
          <div
            key={icon.name}
            // onMouseEnter={() => setHoveredIcon(icon.name)}
            // onMouseLeave={() => setHoveredIcon(null)}
            onClick={() => setSelectedIcon(icon.name)}
            style={{ cursor: 'pointer', display: 'inline-block', height: '100%', width: '100%' }}
          >
            <KTSVG
              path={isSelected ? icon.selectedPath : icon.path}
              svgClassName={`no-fill h-100 w-100 ${isSelected ? 'selected-icon' : ''}`}
            // path={isSelected ? icon.selectedPath : isHovered ? icon.hoverPath : icon.path}
            // svgClassName={`no-fill h-100 w-100 ${isHovered ? 'hover-icon' : ''} ${isSelected ? 'selected-icon' : ''}`}
            />
          </div>
        );
      })}
    </div>
  );
};

export default IconWithHoverAndSelect;
